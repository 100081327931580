import React from 'react'
import { FirebaseAuthProvider, AuthRole } from '@flock/utils'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { blueOvermoonTheme } from '@flock/shared-ui'

import ConfiguredApolloProvider from './apollo'

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }: any) => (
  <ThemeProvider theme={blueOvermoonTheme}>
    <CssBaseline />

    <FirebaseAuthProvider authorizedRoles={[AuthRole.ADMIN]}>
      <ConfiguredApolloProvider>{element}</ConfiguredApolloProvider>
    </FirebaseAuthProvider>
  </ThemeProvider>
)

export const FLOCK_LANDING_URL = process.env.GATSBY_FLOCK_LANDING_URL
export const FLOCK_INVESTOR_SIMULATOR_URL =
  process.env.GATSBY_FLOCK_INVESTOR_SIMULATOR_URL
export const FLOCK_PARTNER_SIMULATOR_URL =
  process.env.GATSBY_FLOCK_PARTNER_SIMULATOR_URL
export const HOME_OPS_BASE_URL = '/app/home-ops'
export const HOME_OPS_URL = `${HOME_OPS_BASE_URL}/lead-management`
export const HOME_OPS_TASKS = `${HOME_OPS_BASE_URL}/tasks`
export const HOME_OPS_CREATE_LEAD = `${HOME_OPS_BASE_URL}/create-lead`
export const SALES_BASE_URL = '/app/sales'
export const SALES_TASKS = `${SALES_BASE_URL}/inbound-tasks`
export const SALES_OUTBOUND_TASKS = `${SALES_BASE_URL}/outbound-tasks`
export const SALES_LEAD_MANAGEMENT_URL = `${SALES_BASE_URL}/lead-management`
export const SALES_LEAD_DOCUMENTS_CENTER_URL = `${SALES_BASE_URL}/lead-document-center`
export const BROKER_MANAGEMENT_URL = `${SALES_BASE_URL}/broker-management`
export const EXCHANGE_URL = '/app/exchange'
export const ORDER_ONBOARDING_URL = `${EXCHANGE_URL}/orders`
export const NEW_CONTRIBUTIONS_URL = `${EXCHANGE_URL}/new-contributions`
export const NEW_ORDER_CREATION_URL = `${EXCHANGE_URL}/new_order`
export const FUND_MANAGEMENT_URL = '/app/fund-management'
export const INVESTOR_MANAGEMENT_URL = `${FUND_MANAGEMENT_URL}/investor-management`
export const DISTRIBUTIONS_MANAGEMENT_URL = `${FUND_MANAGEMENT_URL}/distributions-management`
export const REPORTS_URL = `${FUND_MANAGEMENT_URL}/reports`
export const ADDRESS_SEARCH_URL = '/app/address_search'
export const LOGOUT = '/app/logout'
export const { GATSBY_ENV } = process.env
export const GOOGLE_MAPS_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY

export const DOCUMENTS_TYPE_LEGAL_ENTITY = 'legal_entity'
export const DOCUMENTS_TYPE_INVESTOR_ACCOUNT = 'investor_account'

export const SAM_OPERATOR_UUID = '778bb9f9-464b-4766-9d87-9da403ae05ac'
export const JACOB_OPERATOR_UUID = 'f9c0e463-48a3-4b89-a7ce-437cd8cb8ad2'
export const RAYMOND_OPERATOR_UUID = '7df108e0-19d9-48cf-98ac-395316d902ca'
export const ARI_OPERATOR_UUID = 'a7014674-a842-437e-95da-442c430697e6'
export const COLIN_OPERATOR_UUID = '55a91781-9619-4f4e-8804-05aa76c3d71c'
export const KYLE_OPERARTOR_UUID = 'f90983d9-a363-4348-953e-df773668bf57'

// adding overmoon tenant
export const TENANT = process.env.GATSBY_TENANT

export const allStates = [
  'AK',
  'AL',
  'AR',
  'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MP',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UM',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
]

export const allStatesLongForm = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Federated States of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Island',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]

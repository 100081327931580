import React, { createContext, useContext, useEffect, useState } from 'react'
import { initializeApp } from 'firebase/app'
import { Auth, getAuth, onAuthStateChanged, signOut, User } from 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
}

type FirebaseAuthProviderProps = {
  authorizedRoles: AuthRole[]
  children: React.ReactNode
}

type FirebaseAuthContextProps = {
  user: User | null
  auth: Auth
  authorizedRoles: AuthRole[]
  userRoles: AuthRole[]
  logout: () => Promise<void>
  useValidateLoggedInOrRedirect: () => void
  useValidateAuthLoadedAndLogout: () => void
}

export const FirebaseAuthContext = createContext<FirebaseAuthContextProps>({
  user: null,
  auth: {} as Auth,
  authorizedRoles: [],
  userRoles: [],
  logout: async () => {},
  useValidateLoggedInOrRedirect: () => {},
  useValidateAuthLoadedAndLogout: () => {},
})

export enum AuthRole {
  ADMIN = 'admin',
  INVESTOR = 'investor',
  SKIP_MFA = 'skip_mfa',
}

const FirebaseAuthProvider = (props: FirebaseAuthProviderProps) => {
  const { authorizedRoles, children } = props

  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [userRoles, setUserRoles] = useState<AuthRole[]>([])
  const [authObj, setAuthObj] = useState<Auth | null>(null)

  const useValidateLoggedInOrRedirect = () => {
    useEffect(() => {
      if (authObj) {
        onAuthStateChanged(authObj as Auth, (user) => {
          const continuePage = `${window.location.pathname}${window.location.search}`
          if (!user) {
            // Get the continue page from the URL
            window.location.href = `/login?continue=${continuePage}`
          }
        })
      }
    }, [authObj])
  }

  const useValidateAuthLoadedAndLogout = () => {
    useEffect(() => {
      if (authObj) {
        onAuthStateChanged(authObj as Auth, async () => {
          await signOut(authObj as Auth)
          window.location.href = '/login'
        })
      }
    }, [authObj])
  }

  useEffect(() => {
    const app = initializeApp(firebaseConfig)
    const auth = getAuth(app)
    setAuthObj(auth)

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user)

        // If the user is not authorized, log them out
        const idTokenResult = await user.getIdTokenResult()

        const tokenUserRoles: AuthRole[] = []
        Object.entries(idTokenResult.claims).forEach(([key, value]) => {
          if (
            Object.values(AuthRole).includes(key as AuthRole) &&
            value === true
          ) {
            tokenUserRoles.push(key as AuthRole)
          }
        })
        if (
          !tokenUserRoles.some((role) =>
            authorizedRoles.includes(role as AuthRole)
          )
        ) {
          await signOut(auth)
          window.location.href = '/login'
        } else {
          setUserRoles(tokenUserRoles)
        }
      }
    })
  }, [authorizedRoles])

  return (
    <FirebaseAuthContext.Provider
      value={{
        user: currentUser,
        userRoles,
        auth: authObj as Auth,
        authorizedRoles,
        logout: async () => {
          await signOut(authObj as Auth)
        },
        useValidateLoggedInOrRedirect,
        useValidateAuthLoadedAndLogout,
      }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  )
}

export const useFirebaseAuth = () => {
  const context = useContext(FirebaseAuthContext)
  if (context === undefined) {
    throw new Error(
      'useFirebaseAuth must be used within a FirebaseAuthProvider'
    )
  }
  return context
}

export default FirebaseAuthProvider
